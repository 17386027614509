import { Button, Step, StepContent, StepLabel, Stepper, Typography, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'

export default function GTDSteps() {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Stepper orientation='vertical' activeStep={0}>
      <Step>
        <StepLabel>
          <Typography
            variant={md ? 'body1' : 'h6'}
          >
            Is it actionable?
          </Typography>
        </StepLabel>
        <StepContent>
          <Grid container spacing={2}>
            <Grid>
              <Button
                variant='contained'
                color='success'
                size={md ? 'small' : 'medium'}
              >
                Yes
              </Button>
            </Grid>
            <Grid>
              <Button
                variant='contained'
                color='error'
                size={md ? 'small' : 'medium'}
              >
                Trash
              </Button>
            </Grid>
            <Grid>
              <Button
                variant='contained'
                color='error'
                size={md ? 'small' : 'medium'}
              >
                Someday/maybe
              </Button>
            </Grid>
            <Grid>
              <Button
                variant='contained'
                color='error'
                size={md ? 'small' : 'medium'}
              >
                Reference
              </Button>
            </Grid>
          </Grid>
        </StepContent>
      </Step>
      <Step>
        <StepLabel>
          <Typography
            variant={md ? 'body1' : 'h6'}
          >
            Single step to complete?
          </Typography>
        </StepLabel>
        <StepContent>
          <Grid container spacing={2}>
            <Grid>
              <Button
                variant='contained'
                sx={{ mt: 2, mr: 2 }}
                color='success'
                size={md ? 'small' : 'medium'}
              >
                Yes
              </Button>
            </Grid>
            <Grid>
              <Button
                variant='contained'
                sx={{ mt: 2, mr: 2 }}
                color='error'
                size={md ? 'small' : 'medium'}
              >
                Plan project
              </Button>
            </Grid>
          </Grid>
        </StepContent>
      </Step>
      <Step>
        <StepLabel>
          <Typography
            variant={md ? 'body1' : 'h6'}
          >
            More than two minutes?
          </Typography>
        </StepLabel>
        <StepContent>
          <Button
            variant='contained'
            sx={{ mt: 2, mr: 2 }}
            color='success'
            size={md ? 'small' : 'medium'}
          >
            Yes
          </Button>
          <Button
            variant='contained'
            sx={{ mt: 2, mr: 2 }}
            color='error'
            size={md ? 'small' : 'medium'}
          >
            Do it!
          </Button>
        </StepContent>
      </Step>
      <Step>
        <StepLabel>
          <Typography
            variant={md ? 'body1' : 'h6'}
          >
            For me?
          </Typography>
        </StepLabel>
        <StepContent>
          <Button
            variant='contained'
            sx={{ mt: 2, mr: 2 }}
            color='success'
            size={md ? 'small' : 'medium'}
          >
            Yes
          </Button>
          <Button
            variant='contained'
            sx={{ mt: 2, mr: 2 }}
            color='error'
            size={md ? 'small' : 'medium'}
          >
            Waiting for ...
          </Button>
        </StepContent>
      </Step>
      <Step>
        <StepLabel>
          <Typography
            variant={md ? 'body1' : 'h6'}
          >
            Specific day/time?
          </Typography>
        </StepLabel>
        <StepContent>
          <Button
            variant='contained'
            sx={{ mt: 2, mr: 2 }}
            color='success'
            size={md ? 'small' : 'medium'}
          >
            Resubmission
          </Button>
          <Button
            variant='contained'
            sx={{ mt: 2, mr: 2 }}
            color='error'
            size={md ? 'small' : 'medium'}
          >
            Context
          </Button>
        </StepContent>
      </Step>
    </Stepper>
  );
}