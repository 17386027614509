import React, { createContext, useEffect, useState } from 'react';
import { getDefaultSession, handleIncomingRedirect } from '@inrupt/solid-client-authn-browser'
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import Navigation from './Navigation';
import SpeedDial from '@mui/material/SpeedDial';
import Inbox from './Inbox';
import Login from './Login';

export const LoggedInContext = createContext<[boolean, React.Dispatch<React.SetStateAction<boolean>>]>([getDefaultSession().info.isLoggedIn, () => {}]);

function App() {
  const [loggedIn, setLoggedIn] = useState(getDefaultSession().info.isLoggedIn);

  useEffect(() => {
    if(!loggedIn) {
      handleIncomingRedirect({
        restorePreviousSession: true
      }).then(info => setLoggedIn(info?.isLoggedIn || false));
    }
  });

  return (
    <LoggedInContext.Provider value={[loggedIn, setLoggedIn]}>
      <LoggedInContext.Consumer>
        {([loggedIn, setLoggedIn]) => (
          <Box>
            <CssBaseline />
            <Navigation>
              { loggedIn ? (
                  <Inbox />
                ) : (
                  <Login />
                )
              }
              <SpeedDial
                ariaLabel="Add new inbox item"
                sx={{ position: 'absolute', bottom: 32, right: 32 }}
                icon={<SpeedDialIcon />}
                openIcon={<SpeedDialIcon />}
                onClick={() => console.log('Hi')}
                open={false}
              />
            </Navigation>
          </Box>
        )}
      </LoggedInContext.Consumer>
    </LoggedInContext.Provider>
  );
}

export default App;
