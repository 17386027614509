import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import InboxElement from './InboxElement';
import GTDSteps from './GTDSteps';

export default function Inbox() {
  return (
    <Grid
      container
      spacing={4}
      sx={{ mx: 0, my: 0 }}
      justifyContent="center"
    >
      <Grid
        xs={12}
        md={6}
        pl={{ xs: 0, md: 3 }}
        pr={0}
        py={{ xs: 0, md: 3 }}
        sx={{ maxWidth: 600 }}
      >
        <Paper sx={{ mx: 2, my: 2, px: 2, py: 2}}>
          <GTDSteps />
        </Paper>
      </Grid>
      <Grid
        xs={12}
        md={6}
        pr={{ xs: 0, md: 3 }}
        pl={0}
        py={{ xs: 0, md: 3 }}
        sx={{ maxWidth: 600 }}
      >
        <Grid container spacing={0}>
          <InboxElement active={true} />
          <InboxElement active={false} />
        </Grid>
      </Grid>
    </Grid>
  );
}