import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Unstable_Grid2';

type InboxElementProps = {
  active: boolean
}

export default function InboxElement(props: InboxElementProps) {
  return (
    <Grid xs={12} sx={{ alignContent: 'center' }} pt={{ xs: 0, md: 2 }}>
      <Card raised={true} sx={{ opacity: props.active ? 1 : 0.5 }}>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe">
              R
            </Avatar>
          }
          title="Daniel Schraudner"
          subheader="September 14, 2016"
        />
        <CardContent>
          Lorem ipsum dolor si amet
        </CardContent>
      </Card>
  </Grid>
  );
}