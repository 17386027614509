import * as React from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DeleteIcon from '@mui/icons-material/Delete';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import HourglasTopIcon from '@mui/icons-material/HourglassTop';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import HouseIcon from '@mui/icons-material/House';
import BookIcon from '@mui/icons-material/Book';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import ListSubheader from '@mui/material/ListSubheader';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import NavigationItem from './NavigationItem';
import SolidLogin from './SolidLogin';
import { LoggedInContext } from './App';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function Navigation(props: React.PropsWithChildren) {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <LoggedInContext.Consumer>
      {([loggedIn, setLoggedIn]) => (
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppBar position="fixed" open={open}>
            <Toolbar>
              { loggedIn &&
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{
                    marginRight: 2,
                    ...(open && { display: 'none' }),
                  }}
                >
                  <MenuIcon />
                </IconButton>
              }
              <Typography variant="h5" noWrap component="div" sx={{ flexGrow: 1 }}>
                Solid GTD
              </Typography>
              <SolidLogin />
            </Toolbar>
          </AppBar>
          { loggedIn &&
            <Drawer variant="permanent" open={open}>
              <DrawerHeader>
                <IconButton onClick={handleDrawerClose}>
                  <ChevronLeftIcon />
                </IconButton>
              </DrawerHeader>
              <Divider />
              <List>
                <NavigationItem open={open} text="Inbox">
                  <InboxIcon />
                </NavigationItem>
                <NavigationItem open={open} text="Trash">
                  <DeleteIcon />
                </NavigationItem>
                <NavigationItem open={open} text="Someday/Maybe">
                  <QuestionMarkIcon />
                </NavigationItem>
                <NavigationItem open={open} text="Waiting">
                  <HourglasTopIcon />
                </NavigationItem>
                <NavigationItem open={open} text="Resubmission">
                  <EventRepeatIcon />
                </NavigationItem>
                <NavigationItem open={open} text="Projects">
                  <AccountTreeIcon />
                </NavigationItem>
              </List>
              <Divider />
              <List>
                {open &&
                  <ListSubheader>
                    Contexts
                  </ListSubheader>
                }
                <NavigationItem open={open} text="@Home">
                  <HouseIcon />
                </NavigationItem>
              </List>
              <Divider />
              <List>
                {open &&
                  <ListSubheader>
                    References
                  </ListSubheader>
                }
                <NavigationItem open={open} text="Books">
                  <BookIcon />
                </NavigationItem>
              </List>
            </Drawer>
          }
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
            <DrawerHeader />
            {props.children}
          </Box>
        </Box>
      )}
    </LoggedInContext.Consumer>
  );
}